import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import img from "../images/suspension.jpg";
import a1 from "../images/industrial/A1 - Malmedie Drum Coupling TTX.jpg";
import a2 from "../images/industrial/A2 - Malmedie Gear Coupling LX.jpg";
import a3 from "../images/industrial/A3 - Malmedie Safety Coupling SE.jpg";
import b1 from "../images/industrial/B1 - Malmedie Mining Solution - Gear Coupling SE-GLX.png";
import b2 from "../images/industrial/B2 - Malmedie Mining Solution.png";
import c1 from "../images/industrial/C1 - Malmedie  Mining Solution - Safety Coupling - SE-E.png";
import c2 from "../images/industrial/C2 - Malmedie  Mining Solution.png";

import { MainLayout } from "../layouts/MainLayout";

// markup
const MaterialHandlingEquipment = () => {

  return (
    <MainLayout>
      <section className="section">
        <main className="container">
          <div className="page-content">
            <div className="columns">
              <div className="column">
                <div className="page-title">
                  <h1>SUSPENSION SYSTEMS</h1>
                  {/* <figure className="image is-96x96">
                    <img src={img} alt="Factory" />
                  </figure> */}
                </div>
                <p className="page-description">
                We offer heavy-duty suspension parts and systems, to manage operations smoothly and effectively, 
                delivering precise solutions and unmatched support. This applies to mining, military and construction vehicles.
                </p>
                <figure className="service-image">
                  <img src={img} alt="mining-image" />
                </figure>
                {/* <section className="products">
                  <h5>A few examples of our Industrial Equipments</h5>
                  <ul>
                    <li>
                      <figure>
                        <img src={a1} alt={a1} />
                        <p>Malmedie Drum Coupling TTX</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={a2} alt={a2} />
                        <p>Malmedie Gear Coupling LX</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={a3} alt={a3} />
                        <p>Malmedie Safety Coupling SE</p>
                      </figure>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <figure>
                        <img src={b1} alt={b1} />
                        <p>Malmedie Mining Solution - Gear Coupling SE-GLX</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={b2} alt={b2} />
                        <p>Malmedie Mining Solution</p>
                      </figure>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <figure>
                        <img src={c1} alt={c1} />
                        <p>Malmedie Mining Solution - Safety Coupling - SE-E</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={c2} alt={c2} />
                        <p>Malmedie Mining Solution</p>
                      </figure>
                    </li>
                  </ul>
                </section> */}
              </div>
              <div className="column">
                <section className="page-list">
                  <h3 className="title">Products</h3>
                  <ul>
                  <li>
                      <p className="list-title">
                        <span>Shock Absorbers & Dampeners</span>
                        Our excellent range of shock absorbers are designed to be low on maintenance and high on performance.
                      </p>
                      <p className="leading-brand">Leading Brands Handled</p>
                      <span className="brands"> Koni,</span><span className="brands">AVT</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Suspension Springs & Torsion Bars</span>
                        The suspension systems provide superior control and are custom designed, 
                        to operate smoothly and meet the operating conditions of your industrial application.
                      </p>
                      {/* <p className="leading-brand">Leading Brands Handled</p>
                      <span className="brands">IFM</span>
                      <span className="brands">Pepperl+ Fuchs</span>
                      <span className="brands">Turck</span>
                      <span className="brands">Banner Engineering</span>
                      <span className="brands">Baumer</span> */}
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Brake-parts</span>
                        Our mobile mining equipment and brake-systems are robust and well-designed, 
                        performing consistently under the demanding conditions.
                      </p>
                      <p className="leading-brand">Leading Brands Handled</p>
                      <span className="brands">Knott,</span>
                      <span className="brands">Beral</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Hydro-struts</span>
                        We offer a wide range of hydro-pneumatic suspensions which are exclusive in design, 
                        possessing high levels of performance capabilities.
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands">Koni,</span>
                      <span className="brands">AVT</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Accessories</span>
                        We understand your requirements and enable a range of specific 
                        accessories and custom solutions for the high-tech operations. 
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands">AVT (made on order)</span>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </main>
      </section>
    </MainLayout>
  );
};

export default MaterialHandlingEquipment;
